import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useBanks } from "../api/use-banks";
import { useSuspensePayment } from "../api/use-payment";
import { CloseIcon } from "../assets/close";
import { LockIcon } from "../assets/lock";
import { bankLocales } from "../data/supported-locales";
import { cn } from "../lib/cn";
import { isoToFlag } from "../lib/iso-to-flag";
import { useCustomer } from "../store/customer";
import { Button } from "./ui/button";
import { Dropdown } from "./ui/dropdown";

const formValuesSchema = z.object({
	email: z.string().optional(),
	bankId: z.string().min(1),
});

type FormValues = z.infer<typeof formValuesSchema>;

type Props = {
	from: "/connect/$paymentId" | "/pay/$paymentId";
};

export function BankInfo({ from }: Props) {
	const ref = useRef<HTMLInputElement>(null);
	const navigate = useNavigate({ from });
	const { paymentId } = useParams({ from });
	const { locale } = useSearch({ from });
	const { payment } = useSuspensePayment(paymentId);
	const [search, setSearch] = useState("");
	const { email, setCustomerEmail } = useCustomer();
	const { banks, isLoading } = useBanks({
		paymentId,
		locale,
	});

	const {
		handleSubmit,
		register,
		watch,
		formState: { errors },
		setError,
		setValue,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			email: email ?? "",
			bankId: undefined,
		},
	});

	const selectedBankId = watch("bankId");

	const filteredBanks = useMemo(() => {
		if (!banks) return [];

		return banks.banks
			.filter((bank) => bank.name.toLowerCase().includes(search.toLowerCase()))
			.slice(0, 6);
	}, [banks, search]);

	useEffect(() => {
		if (!banks) {
			return;
		}

		// Detect initial locale on load
		if (banks.locale !== locale) {
			navigate({
				to: from,
				params: (curr) => curr,
				search: (curr) => ({
					...curr,
					locale: banks.locale,
				}),
			});
		}
	}, [banks, navigate, locale, from]);

	function isFormValid() {
		const email = watch("email")?.trim();
		const bankId = watch("bankId");

		if (!bankId || bankId.length === 0) {
			return false;
		}
		if (payment.isEmailRequired && z.string().email().safeParse(email).error) {
			return false;
		}

		return true;
	}

	function onSubmit(values: FormValues) {
		if (values.email) {
			setCustomerEmail(values.email.trim());
		}

		if (
			payment.isEmailRequired &&
			z.string().email().safeParse(values.email).error
		) {
			setError("email", {
				message: "Email required",
			});

			return;
		}

		navigate({
			to: "/connect/$paymentId",
			params: {
				paymentId,
			},
			search: {
				bankId: values.bankId,
				locale: locale || "",
			},
		});
	}

	return (
		<div className="w-full h-[calc(100vh_-_13rem)] md:h-full flex justify-center xl:justify-start items-center">
			<div className="w-full h-full md:h-[75vh] max-w-md flex flex-col space-y-6 px-4 py-8 md:py-4 xl:ml-4 2xl:ml-16">
				<div className="flex items-center justify-between">
					<h2 className="text-lg font-medium text-neutral-700">
						Pay with your bank
					</h2>
					{!locale && isLoading ? (
						<div className="h-[30px] w-24 rounded-xl bg-neutral-100 animate-pulse" />
					) : (
						<Dropdown
							options={bankLocales}
							defaultSelected={
								locale ||
								bankLocales.find(({ value }) => value === banks?.locale)?.value
							}
							onChange={({ value }) => {
								navigate({
									to: from,
									params: (curr) => curr,
									search: (curr) => ({
										...curr,
										locale: value,
									}),
								});
								setValue("bankId", "");
							}}
							render={({ label, value }) => (
								<div className="px-3 py-1 text-left text-sm text-neutral-800 flex space-x-2 items-center">
									<span className="leading-3 mt-1">{isoToFlag(value)}</span>
									<span>{label}</span>
								</div>
							)}
						/>
					)}
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col space-y-6"
				>
					<div className="flex flex-col space-y-4">
						{payment.isEmailRequired ? (
							<fieldset className="flex flex-col space-y-1">
								<div className="flex items-center justify-between">
									<label
										htmlFor="email"
										className="text-sm text-neutral-600 font-medium"
									>
										Email
									</label>
									{errors.email && (
										<span className="text-sm text-red-500">
											{errors.email.message}
										</span>
									)}
								</div>
								<input
									id="email"
									type="text"
									disabled={!!payment.customerEmail}
									placeholder="Billing email address"
									className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 rounded-xl duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
									{...register("email")}
								/>
							</fieldset>
						) : null}
						<fieldset className="flex flex-col space-y-1">
							<div className="flex items-center justify-between">
								<label
									htmlFor="bankSearch"
									className="text-sm text-neutral-600 font-medium"
								>
									Bank account
								</label>
								<span className="text-xs text-neutral-400 font-medium flex space-x-2 items-center">
									<LockIcon className="w-3 h-3 fill-green-400" />
									<span>Secured by Inflow</span>
								</span>
							</div>
							<div className="w-full flex items-center border border-neutral-300 hover:border-neutral-400 rounded-xl has-[:focus-visible]:border-neutral-400 duration-200 ease-in-out transition-all has-[:focus-visible]:outline-none has-[:focus-visible]:border-ring has-[:focus-visible]:ring-[3px] has-[:focus-visible]:ring-blue-700/40 overflow-hidden">
								<input
									ref={ref}
									id="bankSearch"
									type="text"
									placeholder="Search for your bank"
									className="w-full px-3 py-1.5 text-sm outline-none"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<div
									className={cn(
										"px-2 py-1.5",
										search.length === 0 ? "hidden" : "flex",
									)}
								>
									<button
										type="button"
										className="rounded-full duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40"
										onClick={() => {
											setSearch("");
											ref.current?.focus();
										}}
									>
										<CloseIcon className="w-4 h-4 stroke-neutral-500" />
									</button>
								</div>
							</div>
							{errors.bankId && (
								<span className="text-sm text-red-500">
									You must select a bank
								</span>
							)}
						</fieldset>
						<div className="grid grid-cols-2 xxs:grid-cols-3 gap-4">
							{filteredBanks.map((bank, i) => (
								<button
									key={`${bank.id}-${i}`}
									type="button"
									className={cn(
										"w-full h-full p-4 flex flex-col items-center space-y-2 border rounded-xl hover:border-neutral-400 duration-200 ease-in-out transition-all focus-visible:outline-none focus-visible:border-ring focus-visible:ring-[3px] focus-visible:ring-blue-700/40 shadow-sm",
										selectedBankId === bank.id
											? "border-neutral-500 bg-neutral-100 font-semibold"
											: "border-neutral-300",
									)}
									onClick={() => {
										setValue("bankId", bank.id);
									}}
								>
									<img
										src={bank.logo}
										alt={`${bank.name} logo`}
										className="max-w-full h-14 aspect-auto"
									/>
									{/* <span className="text-sm text-center text-wrap line-clamp-2 truncate text-neutral-700">
                    {bank.name}
                  </span> */}
								</button>
							))}
							{filteredBanks.length === 0 && !isLoading && (
								<div className="col-span-2 xxs:col-span-3 flex justify-center items-center p-4 border border-neutral-300 rounded-xl">
									<span className="text-neutral-500">No banks found</span>
								</div>
							)}
							{isLoading &&
								[...Array(6)].fill(null).map((_, i) => (
									<div
										// biome-ignore lint/suspicious/noArrayIndexKey: what else
										key={i}
										className="flex flex-col items-center space-y-2 p-4 border border-neutral-300 rounded-xl animate-pulse"
									>
										<div className="w-14 h-14 bg-neutral-100 rounded-full" />
									</div>
								))}
							{/* <span className="col-span-2 xxs:col-span-3 text-xs text-neutral-500 font-medium flex space-x-2 items-center justify-center">
                <LockIcon className="w-3 h-3 fill-green-400" />
                <span>Secured by Inflow</span>
              </span> */}
							<div className="h-0" />
						</div>
					</div>
					<Button
						intent="solid"
						size="big"
						type="submit"
						className={cn(!isFormValid() && "!text-neutral-400")}
					>
						Pay by bank
					</Button>
					<div className="h-12" />
				</form>
			</div>
		</div>
	);
}
