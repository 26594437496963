import { createRoute, useNavigate, useParams } from "@tanstack/react-router";
import { useEffect } from "react";
import { useLink } from "../api/use-link";
import { Loading } from "../components/common/loading";
import { Message } from "../components/common/message";
import { rootRoute } from "./__root";
import { paymentRoute } from "./pay";

export const paymentLinkRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/l/$linkId",
	component: Page,
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
});

function Page() {
	const navigate = useNavigate();
	const { linkId } = useParams({ from: paymentLinkRoute.to });
	const { link, isError } = useLink(linkId, {
		retry: 1,
	});

	useEffect(() => {
		if (link?.paymentId) {
			navigate({
				to: paymentRoute.to,
				params: {
					paymentId: link.paymentId,
				},
			});
		}
	}, [link?.paymentId, navigate]);

	if (isError) {
		return (
			<Message
				title="Something went wrong"
				content="The page you were looking for could not be found. Please check the URL or contact the merchant."
			/>
		);
	}

	return <Loading className="w-screen h-screen" />;
}
