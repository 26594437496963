import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { bankSchema } from "../schemas/bank";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof getBanks>>>;

type Data = {
	paymentId: string;
	locale?: string | null;
};

async function getBanks({ paymentId, locale }: Data) {
	const url = new URL(
		`/checkout/banks/${paymentId}`,
		import.meta.env.VITE_API_URL,
	);

	if (locale) {
		url.searchParams.append("locale", locale);
	}

	return fetcher(
		url.toString(),
		z.object({
			banks: bankSchema.array(),
			locale: z.string(),
		}),
	);
}

export function banksOptions(props: Data, options: Options = {}) {
	return queryOptions({
		queryKey: ["banks", props.paymentId, props.locale],
		queryFn: () => getBanks(props),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useBanks(props: Data, options: Options = {}) {
	const { data, ...query } = useQuery(banksOptions(props, options));

	return {
		banks: data,
		...query,
	};
}

export function useSuspenseBanks(props: Data, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(banksOptions(props, options));

	return {
		banks: data,
		...query,
	};
}
