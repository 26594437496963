import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof getLink>>>;

async function getLink(id: string) {
	return fetcher(
		`${import.meta.env.VITE_API_URL}/checkout/links/${id}`,
		z.object({
			paymentId: z.string(),
		}),
	);
}

export function linkOptions(id: string, options: Options = {}) {
	return queryOptions({
		queryKey: ["link", id],
		queryFn: () => getLink(id),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useLink(id: string, options: Options = {}) {
	const { data, ...query } = useQuery(linkOptions(id, options));

	return {
		link: data,
		...query,
	};
}

export function useSuspenseLink(id: string, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(linkOptions(id, options));

	return {
		link: data,
		...query,
	};
}
