import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import type { QueryOptions } from "./types";

export type Session = Awaited<ReturnType<typeof getSession>>;
type Options = QueryOptions<Session>;

async function getSession(id: string) {
	return fetcher(
		`${import.meta.env.VITE_API_URL}/checkout/session/${id}`,
		z.object({
			checkoutSessionUrl: z.string(),
		}),
	);
}

export function sessionOptions(id: string, options: Options = {}) {
	return queryOptions({
		queryKey: ["session", id],
		queryFn: () => getSession(id),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useSession(id: string, options: Options = {}) {
	const { data, ...query } = useQuery(sessionOptions(id, options));

	return {
		session: data,
		...query,
	};
}

export function useSuspenseSession(id: string, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(sessionOptions(id, options));

	return {
		session: data,
		...query,
	};
}
