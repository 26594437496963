import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { bankSchema } from "../schemas/bank";
import type { QueryOptions } from "./types";

type Options = QueryOptions<Awaited<ReturnType<typeof getBank>>>;

type Data = {
	paymentId: string;
	bankId: string;
	locale?: string | null;
};

async function getBank({ paymentId, bankId, locale }: Data) {
	const url = new URL(
		`/checkout/banks/${paymentId}`,
		import.meta.env.VITE_API_URL,
	);

	if (locale) {
		url.searchParams.append("locale", locale);
	}

	const { banks } = await fetcher(
		url.toString(),
		z.object({
			banks: bankSchema.array(),
			locale: z.string(),
		}),
	);

	return banks.find(({ id }) => id === bankId) || null;
}

export function bankOptions(props: Data, options: Options = {}) {
	return queryOptions({
		queryKey: ["bank", props.paymentId, props.bankId, props.locale],
		queryFn: () => getBank(props),
		staleTime: 10 * 1000,
		...options,
	});
}

export function useBank(props: Data, options: Options = {}) {
	const { data, ...query } = useQuery(bankOptions(props, options));

	return {
		bank: data,
		...query,
	};
}

export function useSuspenseBank(props: Data, options: Options = {}) {
	const { data, ...query } = useSuspenseQuery(bankOptions(props, options));

	return {
		bank: data,
		...query,
	};
}
