import { createRoute, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { paymentOptions, useSuspensePayment } from "../api/use-payment";
import { CheckIcon } from "../assets/check";
import { InflowIcon } from "../assets/inflow";
import { Message } from "../components/common/message";
import { numberFormatter } from "../lib/number";
import { queryClient, rootRoute } from "./__root";

const searchSchema = z.object({
	inflow_payment_id: z.string(),
});

export const successRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/success",
	component: SuccessPage,
	beforeLoad: ({ search }) => ({
		paymentId: search.inflow_payment_id,
	}),
	loader: async ({ context: { paymentId } }) => {
		await queryClient.ensureQueryData(paymentOptions(paymentId));
	},
	errorComponent: () => (
		<Message
			title="Something went wrong"
			content="The page you were looking for could not be found. Please check the URL or contact the merchant."
		/>
	),
	validateSearch: searchSchema.parse,
});

function SuccessPage() {
	const { inflow_payment_id: paymentId } = useSearch({
		from: "/success",
	});
	const { payment } = useSuspensePayment(paymentId);

	return (
		<div className="mt-20 md:mt-0 md:h-screen mx-auto max-w-xl flex flex-col items-center justify-center space-y-12 px-4">
			<CheckIcon className="w-8 h-8 fill-inflow-800" />
			<div className="w-full flex flex-col items-center space-y-2">
				<h1 className="text-xl font-medium text-neutral-700">
					Thanks for your payment
				</h1>
				<span className="text-sm text-neutral-600 text-center">
					A payment of{" "}
					<strong>
						{numberFormatter(payment.amount / 100, {
							currency: "EUR",
							style: "currency",
						})}
					</strong>{" "}
					will appear on your statement.
				</span>
				<div className="w-full flex flex-col divide-y divide-dashed divide-neutral-300 receipt">
					<ul className="w-full px-4 py-2 bg-neutral-100 rounded-t-lg max-h-96 overflow-y-auto">
						{payment.products.map(({ name, price, quantity }) => (
							<li
								key={`${name}-${price}-${quantity}`}
								className="py-1.5 flex justify-between items-start"
							>
								<div className="flex flex-col">
									<span className="text-sm text-neutral-700">{name}</span>
									<span className="text-sm text-neutral-500">
										Quantity: {quantity}
									</span>
								</div>
								<div className="flex flex-col items-end">
									<span className="text-sm text-neutral-700">
										{numberFormatter((price / 100) * quantity, {
											style: "currency",
											currency: "EUR",
										})}
									</span>
									{quantity > 1 && (
										<span className="text-sm text-neutral-500">
											{numberFormatter(price / 100, {
												style: "currency",
												currency: "EUR",
											})}
											/each
										</span>
									)}
								</div>
							</li>
						))}
					</ul>
					<div className="w-full px-4 py-3.5 flex flex-col bg-neutral-100">
						<div className="flex items-center justify-between">
							<span className="text-sm text-neutral-700">Currency</span>
							<span className="text-sm text-neutral-700">EUR • Euro</span>
						</div>
						<div className="flex items-center justify-between">
							<span className="text-sm text-neutral-700">Total</span>
							<span className="text-sm text-neutral-700">
								{numberFormatter(payment.amount / 100, {
									currency: "EUR",
									style: "currency",
								})}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="flex items-start">
				<span className="text-neutral-500 text-xs font-semibold">
					Powered by
				</span>
				<InflowIcon className="w-12 h-3 fill-neutral-500" />
			</div>
		</div>
	);
}
